import API from 'utils/API';

const getCustomerInfo = async () => {
  const { data } = await API.get(`/customers/me`);

  return {
    ...data,
    // subscriptions: data.subscriptions.filter(({ status }) => status !== 'cancelled'),
  };
};

const changeCustomerPassword = async (passwordData) => {
  const body = {
    ...passwordData,
  };

  const { data } = await API.patch(`/customers/me/password`, body);

  return data;
};

const sendEmailForChangeEmailAddress = async (emailData) => {
  const body = {
    ...emailData,
  };

  const { data } = await API.patch(`/customers/me/email`, body);

  return data;
};

const confirmNewEmailAddress = async (emailData) => {
  const body = {
    ...emailData,
  };

  const { data } = await API.patch(`/customers/me/email/confirm`, body);

  return data;
};

const updatePaymentInfo = async (paymentData) => {
  const { paymentMethodId, ...restPaymentData } = paymentData;

  const body = {
    paymentMethodId,
    address: {
      ...restPaymentData,
    },
  };

  const { data } = await API.patch(`/customers/me/payment`, body);

  return data;
};

const attachPaymentMethod = async (paymentMethodId) => {
  const body = {
    paymentMethodId,
  };

  const { data } = await API.post(`/customers/me/payment`, body);

  return data;
};

const detachPaymentMethod = async () => {
  const { data } = await API.delete(`/customers/me/payment`);

  return data;
};

const updateCustomerInfo = async (customerData) => {
  const body = {
    ...customerData,
  };

  const { data } = await API.patch(`/customers/me`, body);

  return data;
};

export default {
  getCustomerInfo,
  changeCustomerPassword,
  updatePaymentInfo,
  updateCustomerInfo,
  attachPaymentMethod,
  detachPaymentMethod,
  sendEmailForChangeEmailAddress,
  confirmNewEmailAddress,
};
