import axios from 'axios';
import { camelizeKeys } from 'humps';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import redirectTo from './redirectTo';
// parse
const { authToken } = parseCookies();

const API = axios.create({
  baseURL: process.env.BACK_END_URL,
  transformResponse: [...axios.defaults.transformResponse, (data) => camelizeKeys(data)],
});

if (authToken) {
  API.defaults.headers.Authorization = authToken;
}

API.interceptors.response.use((response) => {
  return response;
});

API.interceptors.response.use(
  (response) => {
    if (response.data.token) {
      API.defaults.headers.Authorization = response.data.token;
      setCookie(null, 'authToken', response.data.token, { maxAge: 90 * 24 * 60 * 60, path: '/' });
    }
    return response;
  },
  // eslint-disable-next-line no-shadow
  (error) => {
    if (
      error.response?.status === 401 ||
      error.response?.status === 403 ||
      error.response?.data?.message === 'No customer found'
    ) {
      destroyCookie(null, 'authToken', {
        path: '/',
      });

      // delete information about session from Intercom
      if (window.Intercom) {
        window.Intercom('shutdown');
      }

      redirectTo('/login');
    }

    return Promise.reject(error);
  }
);

export default API;
