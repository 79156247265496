import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { parseCookies } from 'nookies';
import { useRouter } from 'next/router';

const excludedCoupons = ['SAVE60E'];

const PromoBarContext = createContext({});

const PromoBarProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { pathname } = useRouter();
  const [isClosedByCustomer, setIsClosedByCustomer] = useState(false);

  // need to prevent server side warning
  useEffect(() => {
    const { coupon } = parseCookies();

    setIsVisible(false);

    if (excludedCoupons.includes(coupon?.toUpperCase())) {
      return;
    }

    if (!isClosedByCustomer && coupon && !pathname.includes('profile')) {
      setIsVisible(true);
    }
  }, [pathname, isClosedByCustomer]);

  const closePromoBar = () => setIsClosedByCustomer(true);

  return (
    <PromoBarContext.Provider
      value={{
        isVisible: isVisible && !isClosedByCustomer,
        closePromoBar,
      }}
    >
      {children}
    </PromoBarContext.Provider>
  );
};

PromoBarProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PromoBarProvider;

export const usePromoBar = () => useContext(PromoBarContext);
