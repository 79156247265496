import axios from 'axios';
import { camelizeKeys } from 'humps';
import axiosRetry from 'axios-retry';

const APIWithRetries = axios.create({
  baseURL: process.env.BACK_END_URL,
  transformResponse: [...axios.defaults.transformResponse, (data) => camelizeKeys(data)],
  transformRequest: [(data) => camelizeKeys(data), ...axios.defaults.transformRequest],
});
axiosRetry(APIWithRetries, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 1000,
  // eslint-disable-next-line no-unused-vars
  retryCondition: (_error) => true,
});

export default APIWithRetries;
