import { useState } from 'react';

const useModal = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const open = () => setIsOpenModal(true);

  const close = () => setIsOpenModal(false);

  return [isOpenModal, open, close];
};

export default useModal;
