import Router from 'next/router';

const redirectTo = (ctx = {}, location = '/') => {
  if (ctx.res) {
    ctx.res.writeHead(302, {
      Location: location,
    });
    ctx.res.end();
  } else {
    Router.push(location);
  }
};

export default redirectTo;
