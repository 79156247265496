import { toast as toastMessage } from 'react-toastify';

export const toast = ({ title = '', text = '', type = 'success', ...config }) => {
  const message = (
    <>
      <div className="Toast__content">
        {title}
        <div className="Toast__description">{text}</div>
      </div>

      {Object.prototype.hasOwnProperty.call(config, 'autoClose') && !config.autoClose ? null : (
        <div className="Toastify__progress-bar_background " />
      )}
    </>
  );

  return toastMessage[type](message, {
    ...config,
  });
};
