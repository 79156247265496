import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from 'react-query';
import { parseCookies, destroyCookie, setCookie } from 'nookies';
import { useRouter } from 'next/router';
import { toast } from 'components/toast';
import API from 'utils/API';
import useModal from 'hooks/useModal';
import authService from 'services/auth.service';
import customersService from 'services/customers.service';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [resetModalIsOpen, openResetModal, closeResetModal] = useModal();

  const [isAuth, setIsAuth] = useState(false);
  const { authToken } = parseCookies();

  const router = useRouter();

  // mutations
  const { mutate: forgotPassword } = useMutation(authService.forgotPassword, {
    onSuccess: () => openResetModal(),
    onError: () => {
      toast({
        title: 'Error',
        text: 'Something went wrong, please try again',
        type: 'error',
      });
    },
  });

  const { mutate: login, error } = useMutation(authService.login, {
    mutationKey: 'login',
    onSuccess: ({ data, isRemember }) => {
      if (data.token) {
        const authExpiredTime = isRemember ? 90 * 24 * 60 * 60 : null;

        API.defaults.headers.Authorization = data.token;
        setCookie(null, 'authToken', data.token, { maxAge: authExpiredTime, path: '/' });
        destroyCookie(null, 'isReactivateSubscriptionModalHasBeenSeen');
        destroyCookie(null, 'hasCancelledSubscriptionInSession');

        setIsAuth(true);
        router.push('/profile/my-menu');
      }
    },
    onError: (e) => {
      if (e.response?.data?.message === 'Empty password') {
        const { email } = JSON.parse(e.config.data);
        forgotPassword({ email });
      }
    },
  });

  // queries
  const customerQuery = useQuery(['customer'], customersService.getCustomerInfo, {
    // check enabled logic
    enabled: isAuth,
    retry: false,
    onSettled: (customer) => {
      // Detect user when he is logged-in
      if (window.Intercom) {
        window.Intercom('boot', {
          email: customer?.email,
          name: customer?.name,
          user_id: customer?.id,
        });
      }
    },
  });

  useEffect(() => {
    if (authToken) {
      setIsAuth(true);
      return;
    }

    setIsAuth(false);
  }, [authToken]);

  const logout = async () => {
    // delete information about session from Intercom
    if (window.Intercom) {
      window.Intercom('shutdown');
    }

    destroyCookie(null, 'authToken', {
      path: '/',
    });
    delete API.defaults.headers.Authorization;
    setIsAuth(false);

    router.push('/login');
  };

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        error,
        resetModalIsOpen,
        closeResetModal,
        customer: customerQuery.data || {},
        status: customerQuery.status,
        login,
        logout,
        setIsAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
