import API from 'utils/API';
import APIWithRetries from 'utils/APIWithRetries';

const login = async ({ isRemember, ...loginData }) => {
  const body = {
    ...loginData,
  };

  const { data } = await API.post(`/login`, body);
  return { data, isRemember };
};

const logout = async (refreshToken) => {
  const body = {
    data: {
      refreshToken,
    },
  };

  const data = await API.delete(`/logout`, body);
  return data;
};

const register = async (registrationData) => {
  const body = {
    ...registrationData,
  };

  const data = await API.post(`/registration`, body);
  return data;
};

const registerAfterCheckout = async (registrationData) => {
  const body = {
    ...registrationData,
  };

  const data = await APIWithRetries.post(`/automatically-registration`, body);

  return data;
};

const checkEmail = async (email) => {
  const data = await API.get(`/emails/${email}`);

  return data;
};

const forgotPassword = async (values) => {
  const body = {
    ...values,
  };

  const data = await API.post(`/forgot-password`, body);
  return data;
};

const resetPassword = async (values) => {
  const body = {
    ...values,
  };

  const data = await API.post(`/reset-password`, body);
  return data;
};

export default {
  login,
  logout,
  register,
  registerAfterCheckout,
  checkEmail,
  forgotPassword,
  resetPassword,
};
